let db;
const contactStore = "contacts";
const recentStore = "recents";

function insertAsync(store, data){
    return new Promise((resolve) => {
        if(db){
            const req = store.add(data);
            req.onsuccess = (event) => {
                resolve(true);
            };
            req.onerror = (event) => {
                resolve(false);
            };
        } else {
            resolve(false);
        }
    });
}

module.exports = {
    CONTACT_STORE : contactStore,
    RECENT_STORE: recentStore,
    initDB: function(){
        return new Promise((resolve) => {
            const request = indexedDB.open("app-db");
            request.onsuccess = (event) => {
                console.log("Indexed DB is open.");
                db = request.result;
                resolve(true);
            }
            request.onupgradeneeded = () => {
                console.log("Indexed DB is onupgradeneeded.");
                db = request.result;
          
                // if the data object store doesn't exist, create it
                if (!db.objectStoreNames.contains(contactStore)) {
                  db.createObjectStore(contactStore, { keyPath: 'peerId' });
                }

                if (!db.objectStoreNames.contains(recentStore)) {
                    db.createObjectStore(recentStore, { keyPath: 'time' });
                  }
              };
            request.onerror = (event) => {
                console.log("Could not open indexed db.", event.target.errorCode);
                resolve(false);
            }
        });
    },

    insertAll: function(storeName, arrays) {
        return new Promise((resolve) => {
            if(db){
                const tx = db.transaction(storeName, 'readwrite');
                const store = tx.objectStore(storeName);
                Promise.all(arrays.map(data => insertAsync(store, data))).then(result => {
                    resolve(true);
                }).catch(error => {
                    console.error(error);
                    resolve(false);
                })
            } else {
                resolve(false);
            }
        });
      },

    insertOrUpdate: function(storeName, key, data) {
        return new Promise((resolve) => {
            if(db){
                const tx = db.transaction(storeName, 'readwrite');
                const store = tx.objectStore(storeName);
                const request = store.get(key);
                request.onsuccess = (event) => {
                    const requestDelete = store.delete(key);
                    requestDelete.onsuccess = (event) => {
                        store.add(data);
                        resolve(true);
                    }
                    requestDelete.onerror = (event) => {
                        store.add(data);
                        resolve(true);
                    }
                }
                request.onerror = (event) => {
                    console.error("Put data error: ", event.target.errorCode);
                    const requestAdd = store.add(key);
                    requestAdd.onsuccess = (event) => {
                        resolve(true);
                    }
                    requestAdd.onerror = (event) => {
                        resolve(false);
                    }
                }
            } else {
                resolve(false);
            }
        });
      },

    remove: function(storeName, key) {
        return new Promise((resolve) => {
            if(db){
                const tx = db.transaction(storeName, 'readwrite');
                const store = tx.objectStore(storeName);
                const requestDelete = store.delete(key);
                requestDelete.onsuccess = (event) => {
                    resolve(true);
                }
                requestDelete.onerror = (event) => {
                    resolve(false);
                }
            } else {
                resolve(false);
            }
        });
      },

    removeAll: function(storeName) {
        return new Promise((resolve) => {
            if(db){
                const tx = db.transaction(storeName, 'readwrite');
                const store = tx.objectStore(storeName);
                const requestDelete = store.clear();
                requestDelete.onsuccess = (event) => {
                    resolve(true);
                }
                requestDelete.onerror = (event) => {
                    resolve(false);
                }
            } else {
                resolve(false);
            }
        });
      },

    getAll: function(storeName){
        return new Promise((resolve) => {
            if(db){
                const tx = db.transaction(storeName, 'readonly');
                const store = tx.objectStore(storeName);
                const res = store.getAll();
                res.onsuccess = (event) => {
                    resolve(res.result);
                }
                res.onerror = (event) => {
                    resolve(null);
                }
            } else {
                resolve(null);
            }
        });
    },

    get: function(id){
        return new Promise((resolve) => {
            if(db){
                const tx = db.transaction(contactStore, 'readonly');
                const store = tx.objectStore(contactStore);
                const res = store.get(id);
                res.onsuccess = (event) => {
                    resolve(res.result);
                }
                res.onerror = (event) => {
                    resolve(null);
                }
            } else {
                resolve(null);
            }
        });
    }
};